export const FETCH_PRODUCTS_SUCCESS = "FETCH_PRODUCTS_SUCCESS";
export const FETCH_PRODUCT_DETAIL = "FETCH_PRODUCT_DETAIL";

const fetchProductListSuccess = products => ({
  type: FETCH_PRODUCTS_SUCCESS,
  payload: products
});

export const fetchProducts = products => {
  return dispatch => {
    dispatch(fetchProductListSuccess(products));
  };
};
